
import { ref, defineComponent } from 'vue'
import { useStore } from '@/store'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import { subcontractorTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'AddSubcontractor',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SingleCheckBox,
    SelectInput,
    Header
  },
  async setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const items = [
      {
        label: '14 days',
        value: 'D14'
      },
      {
        label: '30 days',
        value: 'D30'
      }
    ]

    const radioData = [
      {
        id: 1,
        value: 'Hour',
        label: 'Hours'
      },
      {
        id: 2,
        value: 'Day',
        label: 'Days'
      },
      {
        id: 3,
        value: 'FixedPrice',
        label: 'Fixed price'
      }
    ]

    const schema = yup.object({
      name: yup.string().required().label('Name'),
      countryCode: yup.string().required().label('Country code'),
      mailingAdress: yup.string(),
      leadContactName: yup.string().required().label('Name'),
      leadContactEmail: yup.string().required().email().label('Email'),
      leadContactPhone: yup.string().required().label('Phone'),
      hourlyRate: yup
        .number()
        .required()
        .typeError('Standard fees must be written as a whole number or as a decimal')
        .label('Hourly rate'),
      standardPaymentTerm: yup.string().required(),
      bankAccount: yup.string().required().label('Bank account detail'),
      bankSortCode: yup.string().required().label('Bank sort code / BSB number')
    })
    const isEdit = ref(route.params.id)

    const addInitialValues = {
      name: '',
      legalId: '',
      VATnumber: '',
      address: '',
      countryCode: 'UK',
      mailingAddressDiff: false,
      mailingAdress: '',
      leadContactName: '',
      leadContactEmail: '',
      leadContactPhone: '',
      financeContactName: '',
      financeContactEmail: '',
      financeContactPhone: '',
      hourlyRate: 0,
      standardPaymentTerm: 'D14',
      bankAccount: '',
      bankSortCode: ''
    }

    const initialValues = isEdit.value
      ? ref<subcontractorTypes.SubcontractorResponse>(await authApi.getSubcontractor(+route.params.id))
      : ref(addInitialValues)

    initialValues.value.hourlyRate = inputFieldFormat(initialValues.value.hourlyRate)
    async function submit(values: subcontractorTypes.SubcontractorBody & { mailingAddressDiff: boolean | undefined }) {
      const reqBody = { ...values, countryCode: 'UK' }
      delete reqBody.mailingAddressDiff
      reqBody.hourlyRate = currencyToInt(+values.hourlyRate)
      const res = isEdit.value
        ? await authApi.updateSubcontactor(reqBody, +route.params.id)
        : await authApi.addNewSubcontactor(reqBody)
      if (res) {
        router.push({ name: 'Subcontractors' })
      }
    }

    return { initialValues, schema, submit, items, radioData, isEdit }
  }
})
