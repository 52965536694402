<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues" v-slot="{ values }">
      <FormWrapper>
        <template #form-head>
          <h2>Add a new subcontractor</h2>
        </template>
        <TextInput name="name" type="text" placeholderText="Company name" label="Their company name" />
        <TextInput
          name="legalId"
          type="text"
          placeholderText="Company number"
          label="Their company number"
          tooltipTitle="Company number"
          tooltipText="A company registration number is a unique combination of numbers and, in some cases, letters. The company number is used to identify your company and verify the fact that it is a legally registered entity. You can find your (or a client’s or subcontractor’s) company number at your country’s registrar of companies."
        />
        <TextInput
          name="VATnumber"
          type="text"
          placeholderText="VAT / GST number"
          label="Their VAT / GST number"
          tooltipTitle="VAT / GST Number"
          tooltipText="A unique code issued to companies which are registered to pay VAT or GST."
        />
        <TextInput name="address" type="text" placeholderText="Address" label="Their registered address" />
        <SingleCheckBox label="This is different to their mailing address" name="mailingAddressDiff" />
        <TextInput
          v-if="values.mailingAddressDiff"
          type="text"
          name="mailingAdress"
          placeholderText="Address"
          label="Their mailing address"
        />
        <TextInput type="text" name="leadContactName" placeholderText="First name" label="Main contact" />
        <TextInput type="email" name="leadContactEmail" placeholderText="Email" />
        <TextInput type="text" name="leadContactPhone" placeholderText="Phone" />
        <TextInput type="text" name="financeContactName" placeholderText="First name" label="Finance team contact" />
        <TextInput type="email" name="financeContactEmail" placeholderText="Email" />
        <TextInput type="text" name="financeContactPhone" placeholderText="Phone" />
        <TextInput label="Standard fees" type="text" name="hourlyRate" placeholderText="Hourly rate" />
        <SelectInput
          label="Standard payment terms"
          selectLabel="Select"
          name="standardPaymentTerm"
          :items="items"
        ></SelectInput>
        <TextInput type="text" name="bankAccount" placeholderText="Account number" label="Bank account details" />
        <TextInput type="text" name="bankSortCode" placeholderText="Sort code / BSB number" />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Cancel" />
          <CustomButton type="submit" :label="isEdit ? 'Update subcontractor' : 'Set up subcontractor'" color="blue" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import { useStore } from '@/store'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import { subcontractorTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'AddSubcontractor',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SingleCheckBox,
    SelectInput,
    Header
  },
  async setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const items = [
      {
        label: '14 days',
        value: 'D14'
      },
      {
        label: '30 days',
        value: 'D30'
      }
    ]

    const radioData = [
      {
        id: 1,
        value: 'Hour',
        label: 'Hours'
      },
      {
        id: 2,
        value: 'Day',
        label: 'Days'
      },
      {
        id: 3,
        value: 'FixedPrice',
        label: 'Fixed price'
      }
    ]

    const schema = yup.object({
      name: yup.string().required().label('Name'),
      countryCode: yup.string().required().label('Country code'),
      mailingAdress: yup.string(),
      leadContactName: yup.string().required().label('Name'),
      leadContactEmail: yup.string().required().email().label('Email'),
      leadContactPhone: yup.string().required().label('Phone'),
      hourlyRate: yup
        .number()
        .required()
        .typeError('Standard fees must be written as a whole number or as a decimal')
        .label('Hourly rate'),
      standardPaymentTerm: yup.string().required(),
      bankAccount: yup.string().required().label('Bank account detail'),
      bankSortCode: yup.string().required().label('Bank sort code / BSB number')
    })
    const isEdit = ref(route.params.id)

    const addInitialValues = {
      name: '',
      legalId: '',
      VATnumber: '',
      address: '',
      countryCode: 'UK',
      mailingAddressDiff: false,
      mailingAdress: '',
      leadContactName: '',
      leadContactEmail: '',
      leadContactPhone: '',
      financeContactName: '',
      financeContactEmail: '',
      financeContactPhone: '',
      hourlyRate: 0,
      standardPaymentTerm: 'D14',
      bankAccount: '',
      bankSortCode: ''
    }

    const initialValues = isEdit.value
      ? ref<subcontractorTypes.SubcontractorResponse>(await authApi.getSubcontractor(+route.params.id))
      : ref(addInitialValues)

    initialValues.value.hourlyRate = inputFieldFormat(initialValues.value.hourlyRate)
    async function submit(values: subcontractorTypes.SubcontractorBody & { mailingAddressDiff: boolean | undefined }) {
      const reqBody = { ...values, countryCode: 'UK' }
      delete reqBody.mailingAddressDiff
      reqBody.hourlyRate = currencyToInt(+values.hourlyRate)
      const res = isEdit.value
        ? await authApi.updateSubcontactor(reqBody, +route.params.id)
        : await authApi.addNewSubcontactor(reqBody)
      if (res) {
        router.push({ name: 'Subcontractors' })
      }
    }

    return { initialValues, schema, submit, items, radioData, isEdit }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
</style>
